import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'verified_small';
const namespace = 'ui-pdp-icon';

const IconVerifiedNormal = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconVerifiedNormal.propTypes = {
  className: string,
};

IconVerifiedNormal.defaultProps = {
  className: null,
};

IconVerifiedNormal.ICON_ID = ICON_ID;

export default React.memo(IconVerifiedNormal);
export { IconVerifiedNormal };
